<template>
  <div class="emergency">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate
      style="background: red"
      :title="title.titlePage"
      :subtitle="title.subtitlePage"
      :img="title.img"
    />
    <InformationsTemplate :card="ambulancia" />
    <InformationsTemplate :card="bombeiros" />
    <InformationsTemplate :card="mulheres" />
    <InformationsTemplate :card="policia" />
    <InformationsTemplate :card="saude" />
    <InformationsTemplate :card="ubs" />
    <InformationsTemplate :card="upa" />
    <p>
      <router-link :to="{ name: 'refugees-menu-PT' }">{{
        $t("emergency.linkName")
      }}</router-link>
    </p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy,
  },
  data() {
    return {
      title: {
        titlePage: this.$t("emergency.titlePage"),
        subtitlePage: this.$t("emergency.subtitlePage"),
        img: "emergency.png",
      },
      mulheres: {
        title: this.$t("emergency.mulheresTitle"),
        text: this.$t("emergency.mulheresText"),
      },
      policia: {
        title: this.$t("emergency.policiaTitle"),
        text: this.$t("emergency.policiaText"),
      },
      ambulancia: {
        title: this.$t("emergency.ambulanciaTitle"),
        text: this.$t("emergency.ambulanciaText"),
      },
      bombeiros: {
        title: this.$t("emergency.bombeirosTitle"),
        text: this.$t("emergency.bombeirosText"),
      },
      saude: {
        title: this.$t("emergency.saudeTitle"),
        text: this.$t("emergency.saudeText"),
      },
      ubs: {
        title: this.$t("emergency.ubsTitle"),
        text: this.$t("emergency.ubsText"),
        link: "https://www.google.com.br/maps/search/unidade+b%C3%A1sica+de+sa%C3%BAde/@-22.8522989,-43.3460279,12z/data=!3m1!4b1",
        linkName: this.$t("emergency.linkName2"),
      },
      upa: {
        title: this.$t("emergency.upaTitle"),
        link: "https://www.google.com.br/maps/search/upa+rio+de+janeiro/@-22.8519519,-43.3460281,12z/data=!3m1!4b1",
        linkName: this.$t("emergency.linkName2"),
      },
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0 0 50px 120px;
}
</style>